.light-btn,
.dark-btn:hover {
	background-color: var(--primary-light);
	color: var(--primary-dark);
}
.dark-btn,
.light-btn:hover {
	background-color: var(--primary-dark);
	color: var(--primary-light);
}

.btn {
	padding: 10px 18px;
	font-weight: 500;
	letter-spacing: 0.1rem;
}
