.accordion-container {
	margin: 30px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.accordion-drop {
	width: 100%;
}

.accordion-drop-btn {
	position: relative;
	border-radius: 5px;
	margin-bottom: 5px;
	width: 100% !important;
	font-size: 1.5rem;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	text-align: left;
	background-color: var(--primary-dark);
}

.accordion-drop-btn:hover {
	cursor: pointer;
	color: var(--primary-dark);
	background-color: var(--primary-light);
}

.btn:focus {
	outline: none;
	box-shadow: unset;
	border: none;
}

.accordion-image {
	border-radius: 8px;
	width: 100%;
	padding: 5px;
	margin: 8px 0;
	height: 220px;
	background-size: cover;
	background-position: center;
}

.accordion-text {
	color: var(--shade-dark);
}

@media only screen and (max-width: 576px) {
	.accordion-drop-btn {
		font-size: 1rem;
		line-height: 1rem;
	}
}
