.counter-card {
	border: none;
}
.counter-header {
	background-color: var(--primary-dark);
	padding: 10px;
}

.counter-header p {
	color: var(--primary-light);
	text-transform: uppercase;
	font-size: 0.95rem;
	text-align: center;
	font-weight: bold;
	line-height: 1.15rem;
	margin: 0;
}

.counter-body h1 {
	margin: 0;
	text-align: center;
	color: green;
	font-weight: bold;
}
