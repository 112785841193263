/* ************************************************************ */
/* -------------   	   GLOBAL CSS VARIABLES       ------------- */
/* ************************************************************ */

:root {
	/* ___________ */
	/* 	 Colors    */
	/* ----------- */
	--primary-dark: #263578;

	--primary-light: rgb(217 217 217);
	--secondary-dark: #5052a9;
	--shade-dark: #3b3838;
	/* ___________ */
	/*   Borders   */
	/* ----------- */
	--brdr-rad-sm: 3px;
	--brdr-rad-lg: 10px;
	--img-rad: 12px;
	/* ___________ */
	/*   Effects   */
	/* ----------- */
	--box-shadow-default: 2px 2px 5px grey;
	/* ___________ */
	/*    Fonts    */
	/* ----------- */
	--font-sm: 0.8rem;
	--font-md: 1.15rem;
	--font-lg: 2rem;
	--font-xl: 3rem;
	--space-txt: 0.15rem;
	/* ----------- */
	/*   SIZES     */
	/* ----------- */
	--container-width: 1300px;
}

/* ****************************************************** */
/* -------------   	   GLOBAL STYLING       ------------- */
/* ****************************************************** */
* {
	box-sizing: border-box;
	font-family: 'roboto';
}
p,
h1,
h2 {
	color: var(--shade-dark);
}
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	letter-spacing: 0.12rem;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
	color: var(--shade-dark);
}

a:hover {
	text-decoration: none;
	color: var(--primary-dark);
}

.toTop {
	position: fixed;
	bottom: 40px;
	right: 35px;
	font-size: 32px;
	opacity: 0;
	color: var(--primary-light);
	transition: 0.25s;
	background-color: #354590;
	border-radius: 50%;
	padding: 7px 6px 7px 9px;
	box-shadow: 2px 2px 4px rgb(58 56 56 / 59%);
}

.toTop.show {
	opacity: 1;
}

.toTop.show:hover {
	cursor: pointer;
}
/* ******************************************************* */
/* -------------   	   UTILITY CLASSES       ------------- */
/* ******************************************************* */

.default-p {
	font-size: var(--font-sm);
	color: var(--shade-dark);
	letter-spacing: var(--space-txt);
}
