.slide-item {
	width: 100%;
	height: 500px;
	background-size: cover;
	background-position: center;
	border-radius: 5px;
}

@media only screen and (max-width: 767px) {
	.slide-item {
		height: 350px;
	}
}
@media only screen and (max-width: 576px) {
	.slide-item {
		height: 250px;
	}
}
