.drop-menu {
	/* how fast sub goes up */
	transition: 0.32s;
}

.activeDrop {
	/* how fast sub drops */
	transition: 0.2s;
}

.mobile-nav-outer-container {
	/* how fast page moves back up */
	transition: 0.2s;
}

.dropMargin {
	/* how fast page geos down */
	transition: 0.32s;
	margin-bottom: 50px;
}

.dropMargin,
.drop-menu,
.mobile-nav-outer-container,
.activeDrop {
	transition-timing-function: ease;
}

.main-nav-container {
	height: 70px;
	width: 100%;
	background-color: var(--primary-light);
	display: flex;
	align-items: center;
	position: relative;
	overflow: visible;
	box-shadow: 0px 2px 5px #0000005c;
}

/* CONTROLLER CONTAINER  */
.nav-controls-container {
	width: 100%;
	height: 100%;
	top: 0;
	position: absolute;
	z-index: 4;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	background-color: var(--primary-light);
}

.drop-menu {
	width: 100%;
	height: 50px;
	position: absolute;
	z-index: 3;
	top: 0px;
	background-color: var(--primary-dark);
	display: flex;
	align-items: center;
	justify-content: flex-end;
	box-shadow: 0px 2px 5px #0000005c;
	padding-right: 20px;
}
.drop-menu-inner {
	width: 100%;
	height: 100%;
	position: relative;
}
.drop-menu a {
	color: white;
}

.activeDrop {
	top: 70px;
}

.mobile-nav-filter {
	display: none;
}

@keyframes enterNav {
	from {
		top: -50px;
		opacity: 0;
	}
	to {
		top: 0px;
		opacity: 1;
	}
}

.sticky {
	position: fixed;
	top: -50px;
	opacity: 0;
	z-index: 1;
	animation: enterNav;
	animation-duration: 0.2s;
	animation-fill-mode: forwards;
}

/* MENUS */

.menu {
	padding-right: 10px;
	margin-left: auto;
	overflow: hidden;
}

.nav-main-controls {
	top: -20px;
	transition: 0.2s;
}

.activeMenu {
	top: 0px;
}

/* LINKS */
.menu-link {
	margin-right: 0;
	padding: 3px 10px;
	text-transform: uppercase;
}

.menu-link:hover {
	cursor: pointer;
}

.activePrimary {
	border-bottom: solid 3px var(--primary-dark);
}

.drop-menu .subLink,
.drop-menu .activeSublink {
	padding: 3px 12px;
	font-size: 0.8rem;
	letter-spacing: 0.05rem;
	text-align: center;
}
.activeSublink {
	background-color: var(--primary-light);
	border-radius: 15px;
	color: var(--primary-dark) !important;
}

.subLink {
	font-size: 0.8rem;
	letter-spacing: 0.03rem;
}

.nav-logo {
	position: fixed;
	z-index: 100;
	top: 5px;
	left: 5px;
	width: 200px;
	margin-left: 10px;
	border: none;
}

.toggle-nav {
	display: none;
	z-index: 200;
	position: fixed;
	right: 15px;
	top: 22px;
	font-size: 20px;
	color: var(--primary-dark);
	background-color: var(--primary-light);
	padding: 8px 7px 8px 9px;
	border-radius: 4px;
}

.dropmenu-brain a {
	font-size: 0.78rem !important;
}

.subLink.active {
	background-color: var(--primary-light);
	border-radius: 15px;
	color: var(--primary-dark);
}

/* ******************/
/* ******************/
/*      MOBILE      */
/* ******************/
/* ******************/

@media only screen and (max-width: 1180px) {
	/* ********** */
	/* CONTAINERS */
	/* ********** */

	.mobile-nav-outer-container {
		height: 100vh;
		position: fixed;
		left: -100%;
	}

	.subLink.active {
		background-color: var(--primary-dark);
		border-radius: 15px;
		color: white !important;
	}

	.main-nav-container {
		transition: 0.2s;
		height: 100vh;
		width: 270px;
		position: absolute;
		top: 0px;
		left: -270px;
		z-index: 2;
		background-color: white;
		padding-top: 87px;
	}

	.main-nav-container,
	.drop-menu {
		box-shadow: none;
	}

	.mobile-nav-outer-container.open {
		display: block;
		z-index: 3;
		left: 0%;
	}

	.main-nav-container.open {
		left: 0px;
	}

	.mobile-links-container {
		width: 100%;
		height: 100%;
		position: relative;
		display: block;
		overflow: scroll;
	}

	.menu,
	.nav-controls-container,
	.drop-menu {
		background-color: transparent;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		height: unset;
		top: 0;
	}

	.nav-main-controls {
		padding-left: 10px;
	}

	.dropmenu-brain,
	.dropmenu-about {
		padding: 5px 0 15px 15px;
		text-align: left;
	}

	/* ***** */
	/* LINKS */
	/* ***** */
	.toggle-nav {
		display: inline-block;
	}

	.toggle-nav:hover {
		cursor: pointer;
	}

	.nav-logo {
		left: -200px;
		position: absolute;
		z-index: 2;
		transition: 0.5s;
		width: 200px;
	}
	.nav-logo.open {
		left: 20px;
	}

	.nav-controls-container a {
		color: var(--primary-dark);
	}

	.drop-menu a {
		color: var(--primary-dark);
	}

	.subLink {
		font-size: 1.3rem;
		padding: 0px 10px 10px 10px;
		text-align: left !important;
	}
	.main-link {
		font-size: 1.4rem;
		letter-spacing: 0.07rem;
	}

	.main-link.active {
		background-color: transparent;
		color: var(--primary-dark) !important;
		border: none !important;
	}

	.close-nav-filter {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #000000a1;
		z-index: -11;
		transition: 0.2s;
		opacity: 0;
	}

	@keyframes filterOn {
		0% {
			z-index: -1;
		}
		10% {
			opacity: 0;
		}
		100% {
			opacity: 1;
			z-index: 3;
		}
	}
	.close-nav-filter.filter-on {
		cursor: pointer;
		position: fixed;
		animation: filterOn;
		animation-duration: 0.2s;
		animation-fill-mode: forwards;
	}
}
