.promise-header {
	display: flex;
	flex-direction: column;
	padding: 30px 5px 10px 5px;
	align-items: center;
	justify-content: center;
}

.promise-header i {
	font-size: 40px;
	color: var(--primary-dark);
	margin-bottom: 15px;
}

.promise-header h1 {
	font-size: 2.5rem;
	margin: 0;
}

@media only screen and (min-width: 767px) {
	.promise-header h1 {
		margin-bottom: 20px;
	}
}
