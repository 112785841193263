.hover-box-container {
	height: 250px;
	margin: 10px 0;
	background-size: cover;
	border-radius: 8px;
}

.hover-box-container i,
.hover-box-container p {
	color: var(--primary-light);
}

.hover-box-container i {
	font-size: 2rem;
	margin-bottom: 15px;
	transition: 0.3s;
}

.hover-box-container p {
	margin: 0;
	font-size: 1.5rem;
	text-transform: uppercase;
	font-weight: bold;
	text-align: center;
	padding: 0 30px;
}

.hover-box-inner {
	width: 100%;
	height: 100%;
	background-color: var(--shade-dark);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transition: 0.2s;
	border-radius: 8px;
}

.hover-box-inner:hover {
	cursor: pointer;
	background-color: #263578a8;
}

.hover-box-inner:hover i {
	margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
	.hover-box-container p {
		font-size: 0.9rem;
		line-height: 1.3rem;
	}

	.hover-box-container {
		margin: 25px 0;
		height: 200px;
	}
}
