/* Landing section */
.landing-container {
	background-image: url('/assets/images/pageAssets/outside-attorney-consults/img-1.jpeg');
	background-position: center;
	background-size: cover;
	min-height: 600px;
	position: relative;
	padding: 50px 0;
}

.landing-bullet-list {
	padding: 20px 0;
}

.landing-logo {
	width: 100%;
}

.landing-bullet {
	display: flex;
	align-items: flex-start;
}

.landing-bullet span {
	font-size: 2rem;
	margin-top: 0rem;
}

.landing-bullet p {
	font-size: 1.5rem;
	line-height: 2.25rem;
	font-weight: bold;
	text-align: left;
}

.landing-bullet p,
.landing-bullet span {
	color: var(--primary-dark);
}

/* We try other cases banner */
.banner-1 h1 {
	text-align: center;
	color: var(--primary-light);
}

/*  3traits */

.trait-col {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.trait-col h2,
.trait-col p,
.trait-icon i {
	color: var(--primary-dark);
}

.trait-icon {
	font-size: 2.5rem;
	width: 5.5rem;
	height: 5.5rem;
	border: solid 3px var(--primary-dark);
	border-radius: 100%;
	padding: 1.3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 1.7rem;
}

/* BANNER 2 */

.banner-2 h1,
.banner-2 p {
	color: var(--primary-light);
}

@media only screen and (max-width: 991px) {
	.landing-logo {
		margin-bottom: 60px;
	}
	.landing-bullet-list {
		width: 80%;
	}
	.vimeo-col {
		height: 350px;
		margin-bottom: 40px;
	}
	.landing-row {
		padding: 0;
	}
}
@media only screen and (max-width: 576px) {
	.landing-logo {
		margin-bottom: 0px;
	}
	.landing-bullet-list {
		width: 90%;
	}
	.vimeo-col {
		height: 300px;
	}
}

@media only screen and (max-width: 1180px) {
	.landing-bullet span {
		font-size: 1.7rem;
		margin-top: 1rem;
		margin-right: 0.8rem;
	}
}
