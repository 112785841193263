.bg-filter {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

@media only screen and (max-width: 900px) {
}
