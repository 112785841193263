.creds-title {
	font-size: 1.7rem;
	margin: 5px 0 8px 0;
}

.creds-li {
	font-size: 1rem;
	line-height: 1.5rem;
	margin: 0 0 10px 0;
}
.creds-li.final {
	margin: 0 0 25px 0;
}

@media only screen and (min-width: 767px) {
	.creds-li,
	.creds-title {
		text-align: left;
	}
}
@media only screen and (max-width: 991px) {
	.creds-title {
		font-size: 1.5rem;
	}
}
