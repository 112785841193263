p {
	font-size: 1.2rem;
	letter-spacing: 0.1rem;
	padding: 0px 20px;
	line-height: 1.9rem;
	text-align: justify;
	margin-bottom: 20px;
}

h1 {
	text-transform: uppercase;
	font-weight: 500;

	letter-spacing: 0.2rem;
	margin: 40px 0 20px 0;
	font-size: 2rem;
}

.bullet-item a,
p a {
	color: var(--secondary-dark);
	overflow-wrap: anywhere;
}

@media only screen and (max-width: 1180px) {
	h1 {
		font-size: 1.5rem;
		margin: 25px auto 15px auto;
		/* padding: 0 20px; */
	}

	h2 {
		font-size: 1.35rem;
	}

	p {
		font-size: 1rem;
		line-height: 1.5rem;
		padding: 0;
		margin: 10px 0;
	}

	p,
	h1,
	h2 {
		text-align: center;
	}
}
