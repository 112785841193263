iframe {
	width: 100%;
	height: 100%;
}

.frame-container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
}
